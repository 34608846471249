import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"

const CadreMaillot = (props) => {
  return (
    <>
      <SEO
        title="Encadrement maillot"
        description="Encadrement maillot : un cadeau de choix pour tous les passionnés de sport et les supporters de toujours d’une équipe"
        canonical={`${process.env.GATSBY_URL_ACTUEL}${props.location.pathname}`}
      />
      <Layout banner={false} location={props.location}>
        <div className="container">
          <h1>Encadrement maillot</h1>
          <p>Passionné de sport, vous souhaitez <strong>encadrer le maillot de votre sportif préféré</strong> ? Depuis votre plus
          jeune âge, vous en rêvez ! Maintenant, c’est possible, vous êtes en possession du maillot de votre
          idole. Afin de le mettre en valeur de la plus belle des façons, optez pour un <Link to="/cadres/Cadre-vitrine/">cadre vitrine</Link> adapté aux
          sujets épais. Il magnifiera votre maillot. Il en fera ressortir les couleurs et les matières avec élégance.</p>
          <p>Commandez votre cadre vitrine sur mesure en quelques clics. Sélectionnez les baguettes de votre
          choix parmi les modèles que nous présentons. Optez pour un coloris de baguette qui se mariera aux
          couleurs de votre maillot exposé dans son encadrement.</p>
          <h2>Quelles sont les caractéristiques d’un encadrement de maillot ?</h2>
          <p>Les <strong>maillots des sportifs professionnels font souvent l’objet d’un encadrement sur mesure</strong>.
          Ceci constitue un cadeau de choix pour tous les passionnés de sport et les supporters de toujours
          d’une équipe.</p>
          <p>Parmi tous les modèles de cadres qui existent, le <Link to="/cadres/Cadre-vitrine/">cadre vitrine</Link> semble le plus approprié. Son
          épaisseur donnera du relief à votre maillot. Et pour davantage de profondeur, choisissez un cadre
          avec rehausse. Les baguettes de rehausse mesurent 15 mm à 30 mm d’épaisseur.</p>
          <p>Le cadre vitrine offre un espace entre votre maillot et le verre protecteur. Ceci permet d’aérer l’espace
          et de protéger des dégâts du temps votre maillot. Les curieux, en effet, seront nombreux à vouloir
          toucher le maillot que vous exposez d’autant plus s’il a appartenu à un sportif de haut niveau connu.</p>
          <p>Afin de <strong>mettre en valeur votre encadrement de maillot</strong>, privilégiez un format de 1 m x 0,80 m. Le
          maillot pourra ainsi être admiré dans son entièreté. Vous pouvez aussi choisir de le plier. Rabattez les
          manches afin de mettre en avant le nom du joueur et son numéro. Dans ce cas, tournez-vous vers un
          encadrement de forme carré plus approprié pour mettre en valeur votre maillot.</p>
          <p>Optez pour un cadre suffisamment épais, esthétique et élégant.</p>
          <p>N’utilisez jamais de passe-partout pour un encadrement de maillot. Celui-ci aura pour effet d’écraser
          votre sujet.</p>
          <h2>Commandez votre encadrement de maillot en quelques clics</h2>
          <p>Obtenez votre encadrement pour maillot facilement sur notre boutique en ligne. Renseignez d’abord
          les dimensions de votre sujet. Cliquez ensuite sur le modèle de cadre vitrine qui vous plaît. Vous
          pouvez importer une photographie de votre maillot afin de simuler le rendu final. Pour vous conforter
          dans votre choix, commandez un échantillon de baguette. Vous vous rendrez alors compte de la
          qualité des finitions et verrez si le coloris choisi s’accorde à votre maillot.</p>
          <p>Sélectionnez la couleur de votre baguette parmi les coloris disponibles. Nous proposons les tons
          suivants : noir, blanc, or, cuivre, argent, couleur bois, marron, gris.</p>
          <p>Notez la quantité souhaitée. Demandez un fond isorel afin de protéger l’encadrement de votre maillot.
          Il agit telle une barrière qui assure la protection du maillot.</p>
          <p>Il ne vous reste plus qu’à passer votre commande. Vous recevrez votre cadre dans un délai de 8
          jours ouvrés.</p>
          <p>Si vous souhaitez obtenir davantage de renseignements, contactez-nous par courriel ou par
          téléphone.</p>
        </div>
      </Layout>
    </>
  )
}

CadreMaillot.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadreMaillot
